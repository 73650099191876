<template>
  <div :style="cssProps" class="portal-app" :class="{ 'has-error': hasError }">
    <div class="error-card" v-if="!isLoading && hasError">
      <IconDevice404 />
      <h3 class="mt-4">{{ $ml.get(errorMessage).title }}</h3>

      <h3 v-if="errorMessage === 'device_not_found'">{{ deviceMac }}</h3>

      <p class="mt-4">
        {{ $ml.get(errorMessage).desc }}
      </p>

      <ObiButton
        v-if="errorMessage === 'wrong_code'"
        class="mb-2"
        color="primary"
        text="Yeniden denemek için tıklayın."
        @click="hasError = false"
      />

      <p class="mt-4">
        <img
          style="max-width: 60px;"
          src="@/assets/images/obifi-blue.svg"
          alt="obifi logo"
        />
      </p>
    </div>
    <div class="container">
      <div class="row mt-2" v-if="showAggreement">
        <ObiButton
          class="mb-2"
          color="danger"
          block
          text="X"
          @click="showAggreement = false"
        />

        <div class="col-12 flags">
          <div @click="selectedLang = 'tr'">
            <img
              style="max-width: 1rem;"
              src="@/assets/images/flags/tr.svg"
              alt="Türkçe"
            />
          </div>
          <div @click="selectedLang = 'en'">
            <img
              style="max-width: 1rem;"
              src="@/assets/images/flags/en.svg"
              alt="English"
            />
          </div>
          <div @click="selectedLang = 'de'">
            <img
              style="max-width: 1rem;"
              src="@/assets/images/flags/de.svg"
              alt="Dutch"
            />
          </div>
          <div @click="selectedLang = 'ru'">
            <img
              style="max-width: 1rem;"
              src="@/assets/images/flags/ru.svg"
              alt="Russian"
            />
          </div>
          <div @click="selectedLang = 'ar'">
            <img
              style="max-width: 1rem;"
              src="@/assets/images/flags/eg.svg"
              alt="Arabic"
            />
          </div>
        </div>
        <ObiText v-html="agreements[currentAggrement][selectedLang]" />

        <ObiButton
          class="mb-2"
          color="danger"
          block
          text="X"
          @click="showAggreement = false"
        />
      </div>

      <div class="row" v-else>
        <div class="login-form" v-if="!isLoading && !hasError">
          <div
            v-if="styles.logo_type !== 'passive'"
            class="portal-header col liner-background d-flex align-items-center justify-content-center"
          >
            <img :src="styles.portal_logo" alt="" class="portal-logo" />
          </div>

          <div
            class="input-components"
            :class="{ 'mx-auto': styles.logo_type === 'passive' }"
          >
            <div v-if="!buy_package" class="container">
              <div class="p-1 mt-4">
                <p class="login-text">
                  {{ $ml.get('welcome_message_title') }}
                </p>
              </div>

              <div class="row mt-2">
                <div class="col-12 flags">
                  <div @click="selectedLang = 'tr'">
                    <img
                      style="max-width: 1rem;"
                      src="@/assets/images/flags/tr.svg"
                      alt="Türkçe"
                    />
                  </div>
                  <div @click="selectedLang = 'en'">
                    <img
                      style="max-width: 1rem;"
                      src="@/assets/images/flags/en.svg"
                      alt="English"
                    />
                  </div>
                  <div @click="selectedLang = 'de'">
                    <img
                      style="max-width: 1rem;"
                      src="@/assets/images/flags/de.svg"
                      alt="Dutch"
                    />
                  </div>
                  <div @click="selectedLang = 'ru'">
                    <img
                      style="max-width: 1rem;"
                      src="@/assets/images/flags/ru.svg"
                      alt="Russian"
                    />
                  </div>
                  <div @click="selectedLang = 'ar'">
                    <img
                      style="max-width: 1rem;"
                      src="@/assets/images/flags/eg.svg"
                      alt="Arabic"
                    />
                  </div>
                </div>
              </div>
              <div
                class="row mt-2 p-2"
                v-if="
                  (is_sale && selected_sale_option !== null) ||
                  (!is_sale && !require_code)
                "
              >
                <div
                  class="col-12 mt-2"
                  v-if="
                    providers &&
                    providers.length > 0 &&
                    styles.provider_layout === 'dropdown'
                  "
                >
                  <ObiTitle
                    col="col-md-12"
                    :title="$ml.get('login_provider')"
                  ></ObiTitle>
                  <b-form-select
                    class="bg-transparent obi-text-secondary w-100"
                    v-model="selectedComponent"
                  >
                    <b-form-select-option
                      class="bg-dark"
                      v-for="provider in providers"
                      :key="provider.id"
                      :value="provider.component"
                      :selected="provider.order == 1"
                    >
                      <p v-if="provider.component === 'TcLogin'">
                        {{ $ml.get('login_with_citizen_id') }}
                      </p>
                      <p v-else-if="provider.component === 'RoomNoLogin'">
                        {{ $ml.get('login_with_room_number') }}
                      </p>
                      <p v-else-if="provider.component === 'SmsLogin'">
                        {{ $ml.get('login_with_sms') }}
                      </p>
                      <p v-else-if="provider.component === 'UserLogin'">
                        {{ $ml.get('login_with_username') }}
                      </p>
                      <p v-else-if="provider.component === 'Temp'">
                        {{ $ml.get('login_temp') }}
                      </p>
                      <p v-else-if="provider.component === 'Email'">
                        {{ $ml.get('login_email') }}
                      </p>
                      <p v-else>
                        {{ $ml.get(provider.component) }}
                      </p>
                    </b-form-select-option>
                  </b-form-select>
                </div>
                <ObiAlert
                  class="col-md-12 mt-4"
                  warning
                  justify="center"
                  v-if="is_sale"
                >
                  <ObiText
                    class="d-flex align-items-center justify-content-between"
                  >
                    <ObiButton
                      :text="$ml.get('change_paid')"
                      @click="selected_sale_option = null"
                    />
                  </ObiText>
                </ObiAlert>

                <component
                  v-if="
                    (providers &&
                      providers.length > 0 &&
                      styles.provider_layout === 'dropdown') ||
                    showOnlySelected
                  "
                  :is="selectedComponent"
                  :rhid="rhid"
                  :appurl="appurl"
                  @showPackage="showPackage"
                  @errorCode="checkError"
                  :options="componentOptions"
                  :agreements="agreements"
                  :paid="selected_sale_option"
                  @showAggrement="showAggreementClick"
                  class="mt-2 mb-4 col-md-12"
                ></component>
                <ObiText
                  class="w-100"
                  v-if="showOnlySelected"
                  @click="showOnlySelected = false"
                >
                  <ObiButton block :text="$ml.get('go_back')" />
                </ObiText>
                <ObiText
                  class="col-md-12"
                  v-else-if="
                    providers &&
                    providers.length > 0 &&
                    styles.provider_layout === 'scroll'
                  "
                  v-for="(provider, index) in providers"
                  :key="provider.id"
                >
                  <fieldset class="provider-wrapper">
                    <legend
                      class="provider-legend"
                      v-if="provider.component === 'TcLogin' && index < 2"
                    >
                      {{ $ml.get('login_with_citizen_id') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="
                        provider.component === 'RoomNoLogin' && index < 2
                      "
                    >
                      {{ $ml.get('login_with_room_number') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="provider.component === 'SmsLogin' && index < 2"
                    >
                      {{ $ml.get('login_with_sms') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="
                        provider.component === 'UserLogin' && index < 2
                      "
                    >
                      {{ $ml.get('login_with_username') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="provider.component === 'Temp' && index < 2"
                    >
                      {{ $ml.get('login_temp') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="provider.component === 'Email' && index < 2"
                    >
                      {{ $ml.get('login_email') }}
                    </legend>
                    <legend class="provider-legend" v-else-if="index < 2">
                      {{ $ml.get(provider.component) }}
                    </legend>

                    <component
                      v-if="index < 2"
                      :is="provider.component"
                      :rhid="rhid"
                      :options="provider.options"
                      :agreements="agreements"
                      :paid="selected_sale_option"
                      @showAggrement="showAggreementClick"
                      @showPackage="showPackage"
                      :appurl="appurl"
                      class="mt-2 mb-4 col-md-12"
                    ></component>
                    <div
                      class="w-100"
                      v-else
                      @click="
                        selectedComponent = provider.component
                        showOnlySelected = true
                      "
                    >
                      <ObiButton
                        v-if="provider.component === 'TcLogin'"
                        block
                        :text="$ml.get('login_with_citizen_id')"
                      />
                      <ObiButton
                        v-if="provider.component === 'RoomNoLogin'"
                        block
                        :text="$ml.get('login_with_room_number')"
                      />
                      <ObiButton
                        v-if="provider.component === 'SmsLogin'"
                        block
                        :text="$ml.get('login_with_sms')"
                      />
                      <ObiButton
                        v-if="provider.component === 'UserLogin'"
                        block
                        :text="$ml.get('login_with_username')"
                      />
                      <ObiButton
                        v-if="provider.component === 'Temp'"
                        block
                        :text="$ml.get('login_temp')"
                      />
                    </div>
                  </fieldset>
                </ObiText>
                <ObiText
                  class="col-md-12"
                  v-else-if="
                    providers &&
                    providers.length > 0 &&
                    styles.provider_layout === 'card'
                  "
                >
                  <fieldset class="provider-wrapper">
                    <legend
                      class="provider-legend"
                      v-if="providers[0].component === 'TcLogin'"
                    >
                      {{ $ml.get('login_with_citizen_id') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="providers[0].component === 'RoomNoLogin'"
                    >
                      {{ $ml.get('login_with_room_number') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="providers[0].component === 'SmsLogin'"
                    >
                      {{ $ml.get('login_with_sms') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="providers[0].component === 'UserLogin'"
                    >
                      {{ $ml.get('login_with_username') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="providers[0].component === 'Temp'"
                    >
                      {{ $ml.get('login_temp') }}
                    </legend>
                    <legend
                      class="provider-legend"
                      v-else-if="providers[0].component === 'Email'"
                    >
                      {{ $ml.get('login_email') }}
                    </legend>

                    <component
                      :is="providers[0].component"
                      :rhid="rhid"
                      :options="providers[0].options"
                      :agreements="agreements"
                      :paid="selected_sale_option"
                      @showAggrement="showAggreementClick"
                      @showPackage="showPackage"
                      :appurl="appurl"
                      class="mt-2 mb-4 col-md-12"
                    ></component>
                  </fieldset>
                  <ObiText class="d-flex">
                    <ObiText
                      class="col-md-6 p-0 mr-2"
                      v-for="(provider, index) in providers.slice(1)"
                      :key="index"
                    >
                      <div
                        class="w-100"
                        @click="
                          selectedComponent = provider.component
                          showOnlySelected = true
                        "
                      >
                        <ObiButton
                          v-if="provider.component === 'TcLogin'"
                          block
                          :text="$ml.get('login_with_citizen_id')"
                        />
                        <ObiButton
                          v-if="provider.component === 'RoomNoLogin'"
                          block
                          :text="$ml.get('login_with_room_number')"
                        />
                        <ObiButton
                          v-if="provider.component === 'SmsLogin'"
                          block
                          :text="$ml.get('login_with_sms')"
                        />
                        <ObiButton
                          v-if="provider.component === 'UserLogin'"
                          block
                          :text="$ml.get('login_with_username')"
                        />
                        <ObiButton
                          v-if="provider.component === 'Temp'"
                          block
                          :text="$ml.get('login_temp')"
                        />
                      </div>
                    </ObiText>
                  </ObiText>
                </ObiText>

                <ObiAlert
                  class="mt-4"
                  v-if="!providers || providers.length === 0"
                  warning
                  justify="center"
                >
                  <ObiText v-html="$ml.get('no_selected_providers')" />
                </ObiAlert>
              </div>

              <div class="row mt-2 p-2" v-if="require_code">
                <ObiAlert class="col-md-12" info justify="center">
                  <ObiText v-html="$ml.get('code_information')" />
                </ObiAlert>

                <ObiForm
                  class="col-md-12"
                  v-if="!isLoading"
                  @submit.prevent="checkCode"
                >
                  <div class="my-4">
                    <ObiInput
                      transparent
                      type="text"
                      name="username"
                      :disabled="isLoading"
                      v-model="formDataCode.code"
                      :label="$ml.get('code')"
                      :placeholder="$ml.get('code')"
                    />
                  </div>
                  <div class="my-4">
                    <ObiCol col="12">
                      <ObiButton
                        block
                        type="submit"
                        :loading="isLoading"
                        :text="$ml.get('verify')"
                      />
                    </ObiCol>
                  </div>
                </ObiForm>
              </div>

              <div
                class="row mt-2 p-2"
                v-else-if="is_sale && selected_sale_option === null"
              >
                <ObiAlert class="col-md-12" info justify="center">
                  <ObiText v-html="$ml.get('paid_information')" />
                </ObiAlert>

                <ObiAlert
                  class="col-md-12"
                  warning
                  justify="center"
                  v-for="(_package, index) in packages"
                  :key="index"
                >
                  <ObiText v-html="_package.name" />
                  <ObiText class="mt-2">
                    <b>
                      {{ $ml.get('total_paid_amount') }} :
                      <i>
                        {{ _package.paid_sale.value }}
                        {{ _package.paid_sale.option.label }}
                      </i>
                    </b>
                  </ObiText>
                  <ObiButton
                    class="mt-2"
                    block
                    @click="buyPackage(_package.id)"
                    :text="$ml.get('buy')"
                  />
                </ObiAlert>
              </div>

              <div
                class="row mx-auto"
                v-if="(is_sale && !selected_sale_option) || !is_sale"
              >
                <div class="col-12 mx-auto mt-4 display-lite" v-if="false">
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                  >
                    <small style="color: #848484;">
                      <a href="#">{{ $ml.get('usages_policy_title') }}</a>
                    </small>
                  </b-form-checkbox>
                </div>

                <div class="mx-auto mt-4 display-lite">
                  <img
                    v-if="$cookie.get('tenant', 'panel') === 'nfr'"
                    style="max-width: 70px;"
                    src="https://cdn.obifi.io/nfr-logo.png"
                    alt="obifi logo"
                  />

                  <img
                    v-if="$cookie.get('tenant', 'panel') === 'wifibulut'"
                    style="max-width: 70px;"
                    src="https://cdn.obifi.io/wifibulut-logo.png"
                    alt="obifi logo"
                  />

                  <img
                    v-if="$cookie.get('tenant', 'panel') === 'quedra'"
                    style="max-width: 70px;"
                    src="https://cdn.obifi.io/quedra-logo.png"
                    alt="obifi logo"
                  />

                  <img
                    v-if="$cookie.get('tenant', 'panel') === 'panel'"
                    style="max-width: 70px;"
                    src="@/assets/images/obifi-logo.svg"
                    alt="obifi logo"
                  />

                  <p class="hizmet mt-2">{{ $ml.get('provider_title') }}</p>
                </div>

                <div
                  class="user-info col-12 text-center text-white mb-2 display-lite"
                >
                  <p class="mt-2" v-if="userMac && userIp">
                    MAC: {{ userMac }} IP: {{ userIp }}
                  </p>
                </div>
              </div>
            </div>
            <div class="container">
              <ObiAlert
                class="col-md-12 mt-4"
                info
                justify="center"
                v-for="upackage in buy_object.packages"
                :key="upackage.id"
                style="min-width: 350px;"
              >
                <ObiText
                  class="d-flex align-items-center justify-content-between"
                >
                  <b>
                    {{ $ml.get('total_time_period') }} :
                    <i>{{ upackage.time }} {{ $ml.get(upackage.period) }}</i>
                  </b>
                </ObiText>
                <ObiText
                  class="d-flex align-items-center justify-content-between"
                >
                  <b>
                    {{ $ml.get('total_paid_amount') }} :
                    <i>{{ upackage.price }} {{ upackage.currency }}</i>
                  </b>
                </ObiText>
                <ObiText
                  class="d-flex align-items-center justify-content-between"
                >
                  <b>
                    {{ $ml.get('max_download_limit') }} :
                    <i>{{ upackage.download }}</i>
                  </b>
                </ObiText>
                <ObiText
                  class="d-flex align-items-center justify-content-between"
                >
                  <b>
                    {{ $ml.get('max_uploadlimit') }} :
                    <i>{{ upackage.upload }}</i>
                  </b>
                </ObiText>
                <ObiButton
                  block
                  class="text-center"
                  :text="$ml.get('buy')"
                  @click="buyPackage(upackage.id)"
                />
              </ObiAlert>
            </div>
          </div>
          <ObiLoading v-if="isLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { each, get } from 'lodash'

//import ErrorHandler from "@/libs/error-handler";
import PanelPortalApi from '@/api/PanelPortalApi'

import TcLogin from '@/components/login-components/TcLogin'
import RoomNoLogin from '@/components/login-components/RoomNoLogin'
import SmsLogin from '@/components/login-components/SmsLogin'
import UserLogin from '@/components/login-components/UserLogin'
import Temp from '@/components/login-components/Temp'
import Email from '@/components/login-components/Email'

import Notify from '@/libs/notify'
import AuthHandler from '@/libs/auth-handler'
import AuthApi from '@/api/AuthApi'
import formDataCode from '@/components/login-components/FormData/form-data-code'
import IconDevice404 from '@/components/Icons/devices/IconDevice404'

export default {
  name: 'MainLogin',
  components: {
    TcLogin,
    RoomNoLogin,
    SmsLogin,
    UserLogin,
    Temp,
    IconDevice404,
    Email,
  },
  watch: {
    selectedLang(newValue) {
      this.$ml.change(newValue)
      if (newValue === 'ar') {
        this.direction = 'rtl'
      } else {
        this.direction = 'ltr'
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  async beforeMount() {
    if (get(this.$route, 'name') === 'preview-portal') {
      await this.loadPortalDetail()
      this.rhid = 'preview'
    } else {
      await this.preparePortal()
    }
  },
  data() {
    return {
      selectedComponent: 'RoomNoLogin',
      showOnlySelected: false,
      formDataCode,
      selectedLang: 'tr',
      direction: 'ltr',
      rhid: null,
      isLoading: false,
      hasError: false,
      buy_package: false,
      errorMessage: null,
      deviceMac: null,
      buy_object: {},
      packages: [],
      styles: {
        color_scheme: 'light',
        background_type: 'image',
        color_type: 'solid',
        background_color: '#FFFFFF',
        background_image: '',
        portal_logo:
          'https://cdn.obifi.io/2021_03_31_17_35_46_rgPJIFwGW3sBNwV.png',
        primary_color: '#2f77e4',
        secondary_color: '#ffffff',
        logo_background_color: '#2f77e4',
        welcome_message_text_color: '#848484',
        form_background_color: '#1c1e21',
        alert_color: '#AB0F0F',
        warning_color: '#FF8E8E',
        info_color: '#027CABF3',
        success_color: '#ECFFE5',
        border_color: '#ECFFE5',
        welcome_font_size: 14,
        logo_type: 'active',
        provider_layout: 'dropdown',
      },
      providers: [],
      rules: [],
      is_sale: false,
      paid_option: null,
      selected_sale_option: null,
      selected_paid_day: 1,
      days: [],
      agreements: null,
      appurl: null,
      require_code: false,
      showAggreement: false,
      kvkk_aggrement: true,
      general_aggrement: true,
    }
  },
  computed: {
    paidCurrency() {
      if (this.is_sale) {
        return this.paid_option.currency.toUpperCase()
      }

      return null
    },
    userMac() {
      if (this.$route.query.user_mac) {
        return this.$route.query.user_mac
      }
      return null
    },
    userIp() {
      if (this.$route.query.user_ip) {
        return this.$route.query.user_ip
      }
      return null
    },
    componentOptions() {
      const availabledProviders = this.providers.filter(
        (provider) => provider.component === this.selectedComponent,
      )

      if (availabledProviders && availabledProviders.length > 0) {
        return availabledProviders[0].options
      }
      return null
    },
    cssProps() {
      let background = 'var(--background-color) !important'
      if (this.styles.background_type === 'image') {
        background = `url('${this.styles.background_image}') center`
      }
      return {
        '--primary-color': this.styles.primary_color,
        '--secondary-color': this.styles.secondary_color,
        '--background-image': this.styles.background_image,
        '--background-color': this.styles.background_color,
        '--form-background-color': this.styles.form_background_color,
        '--input-group-bg-color': this.styles.input_group_bg_color,
        '--logo-background-color': this.styles.logo_background_color,
        '--alert-color': this.styles.alert_color,
        '--warning-color': this.styles.warning_color,
        '--info-color': this.styles.info_color,
        '--success-color': this.styles.success_color,
        '--border-color': this.styles.border_color,
        '--welcome-message-text-color': this.styles.welcome_message_text_color,
        '--logo-scale': `${this.styles.logo_scale}%`,
        '--welcome-font-size': `${this.styles.welcome_font_size}px`,
        '--input-text-color': this.styles.input_text_color,
        '--lite-display': this.styles.display_lite ?? 'block',
        background: background,
        direction: this.direction,
      }
    },
  },
  methods: {
    async showAggreementClick() {
      this.currentAggrement = 'kvkk'
      this.showAggreement = true
    },
    async showPackage(data, rhid, slug, params) {
      this.buy_package = true

      this.buy_object = {
        packages: data.packages,
        rhid,
        slug,
        params,
      }
    },
    async preparePortal() {
      this.isLoading = true

      try {
        const options = await PanelPortalApi.options(this.$route.query)
        if (options.data) {
          each(options.data.styles, (value, key) => (this.styles[key] = value))
          if (
            options.data &&
            options.data.providers &&
            options.data.providers.length > 0
          ) {
            const providers = options.data.providers.map((provider) => {
              let nProvider = {
                id: provider.slug,
                component: provider.component,
                label: provider.label,
                options: provider.options,
              }

              return nProvider
            })
            this.providers = providers
            this.selectedComponent = providers[0].component
          } else {
            AuthHandler.handle(options.data.data)
            return
          }

          this.rules = get(options.data, 'rules')
          this.require_code = get(options.data, 'require_code')
          this.appurl = get(options.data, 'appurl')
          this.packages = get(options.data, 'packages')

          if (this.appurl !== null) {
            window.location = this.appurl
          }

          this.messages = options.data.messages
          this.rhid = options.data.rhid
          this.is_sale = options.data.is_sale
          this.paid_option = options.data.paid_option
          this.agreements = options.data.agreements

          this.$ml.list.forEach((key, index) => {
            if (this.messages[key]) {
              Object.keys(this.messages[key]).forEach(
                (item) => (this.$ml.db[index][item] = this.messages[key][item]),
              )
            }
          })

          this.days = []
          for (var day = 1; day <= 31; day++) {
            this.days.push({ label: day, value: day })
          }
        }
      } catch (err) {
        console.log(err)
        //ErrorHandler.handle(err);
        this.hasError = true
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'error_code')

        if (this.errorMessage == 'redirect_to_url') {
          window.location = get(data, 'message')
        }
        this.deviceMac = get(data, 'message')
      } finally {
        this.isLoading = false
      }
    },

    async loadPortalDetail() {
      this.isLoading = true

      try {
        this.rhid = 'preview'
        const response = await PanelPortalApi.show(
          this.$route.params.portalHid,
          this.$route.query,
        )
        if (response) {
          const data = response.data.data

          each(
            data.style_options,
            (item) => (this.styles[item.key] = item.value),
          )

          this.require_code = data.require_code
          this.appurl = data.appurl

          if (data.providers && data.providers.length > 0) {
            const providers = data.providers.map((provider) => {
              let nProvider = {
                id: provider.slug,
                component: provider.component,
                label: provider.label,
                options: provider.options,
              }

              return nProvider
            })
            this.providers = providers
            this.selectedComponent = providers[0].component
          }

          this.messages = data.messages

          this.$ml.list.forEach((key, index) => {
            if (this.messages[key]) {
              Object.keys(this.messages[key]).forEach(
                (item) => (this.$ml.db[index][item] = this.messages[key][item]),
              )
            }
          })
        }
      } catch (err) {
        console.log(err)
        //ErrorHandler.handle(err);
        this.hasError = true
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
        this.deviceMac = get(data, 'message')
      } finally {
        this.isLoading = false
      }
    },

    receiveMessage(event) {
      if (event.data && event.data.command) {
        if (event.data.command == 'style_changed') {
          this.styles = event.data.value
        }
        if (event.data.command == 'message_changed') {
          this.$ml.list.forEach((key, index) => {
            Object.keys(event.data.value[key]).forEach(
              (item) =>
                (this.$ml.db[index][item] = event.data.value[key][item]),
            )
          })
        }
        if (event.data.command == 'provider_changed') {
          this.providers = event.data.value
        }
      }
    },

    async checkError(code) {
      if (code === 40003) {
        this.is_sale = true
      }
    },

    async buyPackage(profile_id) {
      this.isLoading = true

      if (!this.buy_object.params) {
        this.buy_object.params = {}
      }

      this.buy_object.params.selected_profile_id = profile_id
      const response = await AuthApi.auth(
        this.buy_object.rhid,
        this.buy_object.slug,
        this.buy_object.params,
      )

      if (response.data.success) {
        Notify.success(this.$ml.get('authorized'))
        AuthHandler.handle(response.data.data)
        this.isLoading = false
      } else {
        //ErrorHandler.handle(response.data);
        const data = get(response, 'data') || get(response, 'response.data')
        this.errorMessage = get(data, 'message')
        this.isLoading = false
      }
    },

    async checkCode() {
      if (!this.formDataCode.isValid({ disableDirtyCheck: true })) return

      this.isLoading = true

      try {
        const params = this.formDataCode.toObject()
        params.rhid = this.rhid
        const response = await AuthApi.checkCode(params)

        if (response.data.success) {
          Notify.success(this.$ml.get('verified'))
          this.require_code = false
        } else {
          //ErrorHandler.handle(response.data);
          this.hasError = true
          const data = get(response, 'data') || get(response, 'response.data')
          this.errorMessage = get(data, 'message')
        }
      } catch (err) {
        //ErrorHandler.handle(response.data);
        this.hasError = true
        const data = get(err, 'data') || get(err, 'response.data')
        this.errorMessage = get(data, 'message')
      } finally {
        this.isLoading = false

        this.$emit('end')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color) !important;
  direction: var(direction) !important;
}
.has-error {
  background-color: #f1f3f4 !important;
  height: 100vh !important;
}

.error-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 60px;
  margin-top: 60px;
  max-width: 560px;

  @media (max-width: 375px) {
    margin-top: 0px;
  }
}

.error-card h3 {
  font-size: 22px;
  line-height: 28px;
  color: #d02e26;
  margin-top: 20px;
}

.error-card p {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 18px;
  line-height: 23px;
  color: #848484;
}
</style>
<style scoped>
.portal-app {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  background-size: cover !important;
}
.login-form {
  max-width: 400px !important;
  display: flex;
  flex-direction: column;
}
.flags {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
}
.portal-header {
  padding: 20px;
  background-color: var(--logo-background-color) !important;
}
.col-lg-4 {
  -ms-flex: 412px !important;
  flex: 412px !important;
  max-width: 400px !important;
}

.portal-logo {
  z-index: 10;
  width: var(--logo-scale);
  max-height: 140px;
  max-width: 200px;
}

.user-info {
  background: #000000;
}
.liner-background {
  border-radius: 5px 5px 0 0;
}
.input-components {
  height: 100%;
  background-color: var(--form-background-color);
}

.mt-6 {
  margin-top: 7rem;
}
.login-text {
  font-style: normal;
  font-weight: normal;
  font-size: var(--welcome-font-size);
  line-height: 18px;
  text-align: center;
  color: var(--welcome-message-text-color);
}
.info-text {
  background: rgba(124, 171, 243, 0.12);
  border-radius: 5px;
  color: #ffff;
}

.hizmet {
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #717171;
}
.bg-dark-gray {
  background: #25272a;
  border: 1px solid #2f77e4;
  border-radius: 5px;
}
.bg-dark-gray::placeholder {
  color: white;
}
.provider-wrapper {
  border: 1px var(--border-color) solid;
  margin-bottom: 20px;
  width: 100%;
}
.provider-legend {
  color: var(--secondary-color);
  font-weight: bold;
  width: auto !important;
  text-align: center;
}
.provider-wrapper-or {
  color: var(--secondary-color);
  font-weight: bold;
  width: auto !important;
  text-align: center;
}
.custom-select {
  background-color: #000 !important;
  color: #fff !important;
}
.display-lite {
  display: var(--lite-display) !important;
}
</style>
